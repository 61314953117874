$primary: #0d47a1;
$modal-content-border-radius: 6px;
$modal-content-border-width: 0;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;
$modal-fade-transform: scale(0.9);
$modal-transition: transform 0.2s ease-in-out;

@tailwind base;
@tailwind components;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/custom-forms';

.sr-only {
  display: none;
}

.btn-p,
.btn {
  @apply py-2 px-4 rounded bg-gray-100 transition ease-in-out duration-100 text-gray-600 font-bold;
  &:hover {
    @apply bg-gray-300 shadow-lg;
  }
  &:disabled {
    &:hover {
      @apply shadow-none;
    }
  }
  &.btn {
    &-primary {
      @apply bg-blue-100 text-white;
      &:hover {
        @apply bg-blue-50;
      }
      &:disabled {
        @apply opacity-25;
        &:hover {
          @apply bg-gray-500;
        }
      }
    }
    &-secondary {
      @apply bg-gray-500 text-gray-700;
      &:hover {
        @apply bg-gray-700 text-gray-800;
      }
      &:disabled {
        @apply opacity-25 pointer-events-none;
        &:hover {
          @apply bg-gray-500 text-gray-700;
        }
      }
    }
  }
}

select.form-control {
  // Stoled from bootstrap
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
}

.input,
.form-control,
.custom-select {
  @apply border-2 transition ease-in-out duration-100 border-gray-200 py-2 px-4 leading-tight rounded-md appearance-none h-auto;
  @apply bg-gray-200 #{!important};
  &:focus {
    @apply bg-gray-100 border-blue-100 shadow;
  }
  &::placeholder {
    @apply text-gray-700;
  }
  &:disabled {
    @apply bg-gray-300 border-gray-300 #{!important};
  }
}

.ck {
  &.ck-content {
    p {
      @apply mt-6 leading-normal;
    }
    a {
      @apply text-blue-100 underline;
    }
  }
}

.ck-editor__editable_inline {
  min-height: 500px;
}

.rst-c {
  .rst {
    &__control {
      @apply bg-gray-200 border-2 transition ease-in-out duration-100 border-gray-200 leading-tight rounded-md appearance-none h-auto;
      &--is-focused {
        @apply bg-gray-100 border-blue-100 #{!important};
      }
    }
    &__menu {
      z-index: 10;
    }
    &__multi-value {
      @apply bg-blue-100;
      &__label,
      &__remove {
        @apply text-white font-semibold;
      }
    }
  }
}

.invalid-feedback {
  @apply mb-6 text-red-500;
}

.spinner-border {
  &.text-primary {
    border-color: $primary;
    border-right-color: transparent;
  }
}

@tailwind utilities;

//
// Additionals
//

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.cke_screen_reader_only {
  display: none !important;
}

@import '~slick-carousel/slick/slick.scss';

.react-daterange-picker {
  display: inline-flex;
  position: relative;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  &--disabled {
    background-color: rgb(240, 240, 240);
    color: rgb(109, 109, 109);
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: thin solid gray;
  }

  &__inputGroup {
    // own padding + inputs padding + digits width + dots width
    // min-width: calc('(4px * 3) + ' 0.54em * 8 ' + ' 0.217em * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;

    &__divider {
      padding: 1px 0;
      white-space: pre;
    }

    &__input {
      min-width: 0.54em;
      height: 100%;
      position: relative;
      padding: 0 1px;
      border: 0;
      background: none;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:invalid {
        background: fade(red, 10%);
      }

      &--hasLeadingZero {
        margin-left: -0.54em;
        // padding-left: calc('1px + ' 0.54em);
      }
    }
  }

  &__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;

    &:enabled {
      cursor: pointer;

      &:hover,
      &:focus {
        .react-daterange-picker__button__icon {
          stroke: rgb(0, 120, 215);
        }
      }
    }

    &:disabled {
      .react-daterange-picker__button__icon {
        stroke: rgb(109, 109, 109);
      }
    }

    svg {
      display: inherit;
    }
  }

  &__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;

    &--closed {
      display: none;
    }

    .react-calendar {
      border-width: thin;
    }
  }
}

.article-content {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  ul {
    @apply list-disc;
  }
  ol {
    @apply list-decimal;
  }
  ol,
  ul {
    @apply mt-3;
    li {
      @apply ml-6;
      @apply pl-3;
      @apply mb-3;
    }
  }
  a {
    @apply text-blue-200;
    @apply underline;
    @apply font-semibold;
    &:hover {
      @apply text-blue-100;
    }
  }
}
